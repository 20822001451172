import { Button, Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined, CloudOutlined, CreditCardOutlined, DollarCircleOutlined, ExportOutlined, FormatPainterOutlined, LockOutlined, MailOutlined, MoneyCollectOutlined, NotificationOutlined, SafetyCertificateOutlined, SecurityScanOutlined, UserAddOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import Paragraph from 'antd/lib/typography/Paragraph';
import "./Start.css";
import Page from '../../Components/Page/Page';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';

const Start = () => {
  const navigation = useNavigate();
  const GetText = TextService.GetText;

  const createClick = () => {
    navigation("/" + getCurrentLanguage() + "/CreateUser")
  };

  return <Page className='bookyStartPage' pageTitle={GetText("PageTitle_Start")}>
    <Row justify="center" align="top">
      <Col span={8} className='create-account-box'>
        <Title className="schedule-title" level={1}>{GetText("Start_Title")}</Title>
        <div className="free-service"><Paragraph> <span dangerouslySetInnerHTML={{ __html: GetText("Start_Description") }} /> <span dangerouslySetInnerHTML={{ __html: GetText("Start_Description1") }} /></Paragraph></div>
        <Button size="large" onClick={createClick} type="primary">{GetText("Start_Register")}<UserAddOutlined /></Button>
      </Col>
      <Col className='startPageImg' span={8}>
        <img alt="Start" src={require('../../start2.jpg')}></img>
      </Col>
    </Row>
    <Divider />

    <Row justify="center" align="top" className="iconWindow">
      <Col span={4}>
        <CheckCircleOutlined style={{ fontSize: '40px', color: 'green' }} /> <Title className="schedule-title-boxes" level={4}>{GetText("Start_EventInShort1")}</Title>
      </Col>
      <Col span={4}>
        <CreditCardOutlined style={{ fontSize: '40px', color: '#002147' }} /> <Title className="schedule-title-boxes" level={4}>{GetText("Start_EventInShort2")}</Title>
      </Col>
      <Col span={4}>
        <ClockCircleOutlined style={{ fontSize: '40px', color: '#C82401' }} /> <Title className="schedule-title-boxes" level={4}>{GetText("Start_EventInShort3")}</Title>
      </Col>
      <Col span={4}>
        <SafetyCertificateOutlined style={{ fontSize: '40px', color: '#FF5F1F' }} /> <Title className="schedule-title-boxes" level={4}>{GetText("Start_EventInShort4")}</Title>
      </Col>
    </Row>

    <Row justify="center" align="top" style={{ paddingTop: "100px", backgroundColor: "white" }}>
      <Col span={15}>
        <Row justify="center" className="bookyBox elevatedWindow" align="top">
          <Col span={6} className="">
            <UserSwitchOutlined /><br />{GetText("Start_EventStep1")}
          </Col>

          <Col span={6} className="">
            <CalendarOutlined /><br />{GetText("Start_EventStep2")}
          </Col>

          <Col span={6} className="">
            <MailOutlined /><br />{GetText("Start_EventStep3")}
          </Col>
        </Row>
      </Col>
    </Row>
    <Row justify="center" className='eventbookyfeatures' align="top" style={{ paddingTop: "50px", backgroundColor: "white" }}>
      <Col span={14}>
        <h2 style={{ fontSize: "30px" }}>{GetText("Start_FeaturesTitle")}</h2>
        <Row justify="center" align="top" style={{ backgroundColor: "white", paddingTop: "40px", marginBottom: "20px" }}>
          <Col span={12} className="">
            <div className="feature-container">
              <div className='feature-icon'>
                <CalendarOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle1")}</div>
                <span>{GetText("Start_EventFeatureDescription1")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <LockOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle2")}</div>
                <span>{GetText("Start_EventFeatureDescription2")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <ClockCircleOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle3")}</div>
                <span>{GetText("Start_EventFeatureDescription3")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <CloudOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle4")}</div>
                <span>{GetText("Start_EventFeatureDescription4")}</span>
              </div>
            </div>

          </Col>

          <Col span={12}>
            <div className="feature-container">
              <div className='feature-icon'>
                <ExportOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle5")}</div>
                <span>{GetText("Start_EventFeatureDescription5")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <MailOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle6")}</div>
                <span>{GetText("Start_EventFeatureDescription6")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <FormatPainterOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle7")}</div>
                <span>{GetText("Start_EventFeatureDescription7")}</span>
              </div>
            </div>


            <div className="feature-container">
              <div className='feature-icon'>
                <DollarCircleOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle8")}</div>
                <span>{GetText("Start_EventFeatureDescription8")}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Page>
};

export default Start;