import { Form, Input } from "antd";
import { validateEmail } from "../../Helpers/Validators";
import { getMemberFieldSetting } from "../../Helpers/MemberFieldsHelper";
import { MemberFieldTypes } from "../../Types/MemberFieldTypes";
import { TextService } from "../../Services/TextService";
import { mapDynamicInputLabel } from "../../Mappers/mapDynamicInputLabel";
import { mapDynamicInputName } from "../../Mappers/mapDynamicName";
import { mapDynamicRequiredMessage } from "../../Mappers/mapDynamicRequiredMessage";

export const BookyDynamicInput = (props: any) => {

  const field = getMemberFieldSetting(props.fieldType, props.memberFieldSettings);
  const GetText = TextService.GetText;
  let rules: any = [];
  if (props.isAdmin && field.mandatoryAdmin || !props.isAdmin && field.mandatoryPublic) {
    rules.push({
      required: true,
      message: mapDynamicRequiredMessage(field.columnType!),
    });
  }

  if (props.validateEmailIfFilledIn) {
    rules.push({
      message: GetText("Common_InvalidEmail"),
      validator: (_: any, value: any) => {
        if (value == undefined || value.length == 0 || validateEmail(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject('Some message here');
        }
      }
    });
  }

  const isPublicUniqueIdField = !props.isAdmin && field.columnType === MemberFieldTypes.UniqueId;
  if (isPublicUniqueIdField) {
    rules = [];
  }

  if (field.visibleInAdmin && props.isAdmin || field.visiblePublic && !props.isAdmin) {
    return (<Form.Item label={mapDynamicInputLabel(field.columnType!, props.memberFieldSettings)} name={mapDynamicInputName(field.columnType!)}
      rules={rules}
    >
      {(() => {
        return <Input type={props.type} disabled={isPublicUniqueIdField} onChange={props.onChange} placeholder={field.helpTextPublic && !props.isAdmin ? field.helpTextPublic : ""} />;
      })()
      }
    </Form.Item >)
  }

  return (null);
}