export const OneFront_en = [
    { id: "Common_PageTitle", value: "OneFront - Digital Member Management & CRM" },
    { id: "Common_Delete", value: "Delete" },
    { id: "Common_Premium", value: "Premium" },
    { id: "Common_Free", value: "Free" },
    { id: "Common_Save", value: "Save" },
    { id: "Common_Members", value: "Members" },
    { id: "Common_Price", value: "Price" },
    { id: "Common_Email", value: "Email" },
    { id: "Common_GlobalPermisison", value: "Full permission" },
    { id: "Common_EditPermission", value: "Edit permission" },
    { id: "Common_ReadPermission", value: "Read permission" },
    { id: "Common_PageNotFoundTitle", value: "Page not found" },
    { id: "Common_UpgradeToPremium", value: "Upgrade to premium" },
    { id: "Common_ChooseFile", value: "Choose file" },
    { id: "Common_UploadComplete", value: "Upload finished" },
    { id: "Common_Saved", value: "Saved!" },
    { id: "Common_SettingsUpdated", value: "Settings updated." },
    { id: "Common_InvalidEmail", value: "Invalid e-mail" },
    { id: "Common_Yes", value: "Yes" },
    { id: "Common_No", value: "No" },
    { id: "Common_Help", value: "Help" },
    { id: "Common_Delete", value: "Remove" },
    { id: "Common_Cancel", value: "Cancel" },
    { id: "Common_SureToDelete", value: "Are you sure to delete?" },
    { id: "AutoComplete_Placeholder", value: "Enter e-mail address" },
    { id: "AutoComplete_Click", value: "Add e-mail" },
    { id: "AutoComplete_HelpTextAddEmail", value: "Add multiple e-mails at the same time by entering them with colon between e-mail adresses.: <br />steve.smith@gmail.com,jonas.anderson@outlook.com" },
    { id: "GooglePlaceAutoComplete_Placeholder", value: "Add event location" },
    { id: "MemberAutocomplete__Placeholder", value: "Search name/e-mail" },
    { id: "BookyEventList_EmptyList", value: "No events added yet. Click on the calender to start adding date/times." },
    { id: "BookyEventList_EmptyList_AllDayEvent", value: "All-day event" },
    { id: "WeekCalendar_AllDay", value: "All-day" },
    { id: "DeleteReply_MessageRequired", value: "Please fill in message" },
    { id: "DeleteReply_MessagePlaceHolder", value: "Message to guest" },
    { id: "EventBranding_SuccessSaved", value: "Branding of the event is updated!" },
    { id: "EventBranding_ChangeColorEventName", value: "Change color of the text of the event name:" },
    { id: "EventBranding_ChangeColorEventPrimaryColor", value: "Change primary color of the event (color on buttons, links):" },
    { id: "EventBranding_ChangeColorEventSecondaryColor", value: "Change secondary color of the event (button text color):" },
    { id: "EventBranding_SaveColors", value: "Save colors" },
    { id: "EventBranding_ResetColors", value: "Reset colors" },
    { id: "BookyPaymentStatusSelect_Select", value: "PaymentStatus" },
    { id: "BookyPaymentStatusSelect_Payed", value: "Payed" },
    { id: "BookyPaymentStatusSelect_Unpayed", value: "Unpayed" },
    { id: "EventDetails_ResetColors", value: "Notifications for event is updated." },
    { id: "EventDetails_NotificationToolTip", value: "Disable/Enable e-mail notification when someone replies to your event." },
    { id: "EventDetails_EventOpenQuestion", value: "Do you wish to open the event again?" },
    { id: "EventDetails_PollIsOpen", value: "Event is open" },
    { id: "EventDetails_PollIsClosed", value: "Event has been closed" },
    { id: "EventDetails_EventStatus", value: "State:" },
    { id: "EventDetails_EventDescription", value: "Description:" },
    { id: "EventDetails_EventLocation", value: "Location:" },
    { id: "EventDetails_EventTimeZone", value: "TimeZone:" },
    { id: "EventDetails_EventParticipants", value: "Participants (max):" },
    { id: "EventDetails_EventDeadline", value: "Deadline:" },
    { id: "InviteList_EmailColumn", value: "Email" },
    { id: "InviteList_SentColumn", value: "Sent" },
    { id: "InviteList_RepliedColumn", value: "Has replied" },
    { id: "InviteList_LinkColumn", value: 'Link to invite' },
    { id: "InviteList_DeleteInviteConfirmMessage", value: "Remove invite, are you sure?" },
    { id: "InviteList_InviteLinkText", value: 'Invite link' },
    { id: "InviteList_NoInvitesYet", value: 'No invites has been sent to your guests yet.' },
    { id: "ReminderForm_MessageTextDefault1", value: 'Hi, ' },
    { id: "ReminderForm_MessageTextDefault2", value: " has invited you to respond to their event. This is friendly reminder to answer the event, '" },
    { id: "ReminderForm_MessageTextDefault3", value: "' please let them know what's a best time for you." },
    { id: "ReminderForm_RemindersSuccessMessage", value: 'Reminders has been sent' },
    { id: "ReminderForm_AutomaticTurnedOnText", value: 'Automatic reminder will be sent' },
    { id: "ReminderForm_AutomaticTurnedOffText", value: 'Automatic reminder is turned off for this event.' },
    { id: "ReminderForm_InvitesStillWithoutReply", value: 'invite has still not replied. Write a text below and send a reminder.' },
    { id: "ReminderForm_SendRemindersToColumn", value: "Send reminders to" },
    { id: "ReminderForm_ReminderListHeader", value: "Send reminder to emails" },
    { id: "ReminderForm_RemindersMessageFormText", value: "Message" },
    { id: "ReminderForm_RemindersMessageMandatoryText", value: 'Message is mandatory' },
    { id: "ReminderForm_SendButtonText", value: 'Send reminders' },
    { id: "ReminderForm_UpgradeToPremium", value: 'to start sending reminders.' },
    { id: "ReminderForm_MessageLabel", value: 'Message' },
    { id: "ReplyTable_Headline", value: 'Participants' },
    { id: "ReplyTable_EditReplyOnlyForPremium", value: "Edit replies is only for Premium account. Go to 'Pricing' page to upgrade." },
    { id: "ReplyTable_EventAlreadyClosed", value: "This event is already closed." },
    { id: "ReplyTable_EventClosedDefaultText1", value: "Hi,\rWe now have a date set for " },
    { id: "ReplyTable_EventClosedDefaultText2", value: " event. \rThe date is " },
    { id: "ReplyTable_EventClosedDefaultText3", value: "\r\rThank everyone for your participation!\rBest Regards, " },
    { id: "ReplyTable_EventHasBeenClosed", value: "Your poll has now been closed." },
    { id: "ReplyTable_CloseEventModalDescription", value: "Write a message to your guests in the below textbox informing them on the chosen date. The Event will then be closed for more answers." },
    { id: "ReplyTable_EventCloseHelpText", value: "To close the Event: Click on a date and select it as final." },
    { id: "SendInviteForm_SendDefaultText0", value: "Hi, " },
    { id: "SendInviteForm_SendDefaultText1", value: "has invited you to respond to their event, '" },
    { id: "SendInviteForm_SendDefaultText2", value: "' Let them know what's the best time for you." },
    { id: "SendInviteForm_SendDefaultText2", value: "' Let them know what's the best time for you." },
    { id: "SendInviteForm_ErrorMaxParticipants", value: 'Maximum 25 invites for Free acount. Upgrade to Premium to send more Invites.' },
    { id: "SendInviteForm_ErrorZeroEmails", value: 'You have to fill in atleast one (1) email.' },
    { id: "SendInviteForm_ErrorEmptyGoupName", value: 'You have to fill in a group name.' },
    { id: "SendInviteForm_ConfirmText", value: 'Do you want to send out the invites?' },
    { id: "SendInviteForm_ConfirmDescription1", value: 'You have selected ' },
    { id: "SendInviteForm_ConfirmDescription2", value: " guests." },
    { id: "SendInviteForm_GroupNameAlreadyExist", value: "Group name already exist. Please change name and try again." },
    { id: "SendInviteForm_MessageSuccess", value: 'Invites sent' },
    { id: "SendInviteForm_InviteDescription", value: "With invites you can send a personal link to your guests. And keep track who has responded and who has not. You can also send reminders for those who has not yet responded." },
    { id: "SendInviteForm_GetContactButtonText", value: "Get Contacts from Google" },
    { id: "SendInviteForm_GetContactButtonTextLoaded", value: "Contacts from Google is loaded" },
    { id: "SendInviteForm_GetContactFromGroup", value: "Get contacts from group " },
    { id: "SendInviteForm_EmailAdressesForm", value: "Email addresses" },
    { id: "SendInviteForm_EmailAdressesPlaceHolder", value: "Enter e-mail address" },
    { id: "SendInviteForm_AddmultipleDescription", value: "Add multiple e-mails at the same time by entering them in colon separated:" },
    { id: "SendInviteForm_UpgradeMoreGroups", value: "to create more then 1 group." },
    { id: "SendInviteForm_SaveEmailsAsGroup", value: "Save e-mails above as a group re-use in other events." },
    { id: "SendInviteForm_GroupNameForm", value: "Group name" },
    { id: "SendInviteForm_GroupNameMandatory", value: 'Group name is mandatory' },
    { id: "SendInviteForm_GroupNamePlaceHolder", value: "Enter the name of the new group" },
    { id: "SendInviteForm_GroupNameMessage", value: "Message" },
    { id: "SendInviteForm_GroupMessageMandatory", value: 'Group name is mandatory' },
    { id: "SendInviteForm_SendButtonText", value: "Send invite to guests" },
    { id: "SendInviteForm_UpgradeToInviteMoreGuests", value: "to invite more then 25 people." },
    { id: "ReplyTimeForm_Accepted", value: "Accepted" },
    { id: "ReplyTimeForm_Tentative", value: "Tentative" },
    { id: "ReplyTimeForm_Rejected", value: "Rejected" },
    { id: "ReplyTimeForm_NoAnswers", value: "No answers yet" },
    { id: "ReplyTimeForm_YesButtonText", value: "Yes" },
    { id: "ReplyTimeForm_MaybeButtonText", value: "Maybe" },
    { id: "ReplyTimeForm_NoButtonText", value: "Cannot attend" },
    { id: "ReplyTimeForm_GuestsAnsweredYes", value: "guest answered 'Yes'" },
    { id: "ReplyTimeForm_ShowOtherGuestReplyText", value: "See what others guests replied" },
    { id: "ReplyTimeForm_OtherAnswersNameColumn", value: "Name" },
    { id: "ReplyTimeForm_OtherAnswersEmailColumn", value: "Email" },
    { id: "ReplyTimeForm_OtherAnswersAnswerColumn", value: "Answer" },
    { id: "CreateGroup_CreateText", value: "Create group" },
    { id: "CreateGroup_CancelText", value: "Cancel" },
    { id: "CreateGroup_RequiredText", value: "Please fill in name of group" },
    { id: "CreateGroup_PlaceHolderText", value: "New group name" },
    { id: "CreateGroup_ModalHeader", value: "Create a new group" },
    { id: "CreateGroup_ErrorAlreadyExist", value: "Group name already exist. Please change name and try again." },
    { id: "EditMember_ErrorSaving", value: "Error updating member. Pleaase try again later." },
    { id: "EditMember_ErrorSaving", value: "Error updating member. Pleaase try again later." },
    { id: "EditMember_ModalTitle", value: "Edit member" },
    { id: "EditMember_ModalOk", value: "Save" },
    { id: "EditMember_ModalCancel", value: "Cancel" },
    { id: "EditMember_FirstName", value: "Firstname" },
    { id: "EditMember_Email", value: "Email" },
    { id: "EditMember_Email2", value: "Email2" },
    { id: "EditMember_LastName", value: "Lastname" },
    { id: "EditMember_PhoneNumber", value: "Phone" },
    { id: "EditMember_UniqueId", value: "Member Id" },
    { id: "EditMember_Address", value: "Address" },
    { id: "EditMember_PostalCode", value: "Postalcode" },
    { id: "EditMember_PostalArea", value: "Postal area" },
    { id: "EditMember_Company", value: "Company name" },
    { id: "EditMember_Country", value: "Country" },
    { id: "EditMember_EditButtonText", value: "Details" },
    { id: "EditMember_Groups", value: "Groups" },
    { id: "EditMember_Title", value: "Profession" },
    { id: "EditMember_Extra1", value: "Extra1" },
    { id: "EditMember_Extra2", value: "Extra2" },
    { id: "EditMember_Extra3", value: "Extra3" },
    { id: "EditMember_Status", value: "Status" },
    { id: "EditMember_FirstName_Required", value: "You have to fill in firstname." },
    { id: "EditMember_LastName_Required", value: "You have to fill in lastname." },
    { id: "EditMember_Email_Required", value: "You have to fill in email." },
    { id: "EditMember_Phone_Required", value: "You have to fill in phone." },
    { id: "EditMember_Address_Required", value: "You have to fill in adress." },
    { id: "EditMember_PostalArea_Required", value: "You have to fill in postalarea." },
    { id: "EditMember_PostalCode_Required", value: "You have to fill in postalcode." },
    { id: "EditMember_Mandatory", value: "Required" },
    { id: "EditMember_Payment", value: "Payment" },
    { id: "EditMember_EmailNotificationPreference", value: "Do not want information per e-mail" },
    { id: "MemberFields_FieldName", value: "Fieldname" },
    { id: "MemberFields_VisibleInAdmin", value: "Visible for admin" },
    { id: "MemberFields_VisibleInAdmin", value: "Visible admin" },
    { id: "MemberFields_VisibleInPublic", value: "Visible member" },
    { id: "MemberFields_MandatoryInAdmin", value: "Mandatory admin" },
    { id: "MemberFields_MandatoryInPublic", value: "Mandatory member" },
    { id: "MemberFields_HelpTextPublic", value: "Helptext member" },
    { id: "MemberFields_FieldNameVisible", value: "Visible name" },
    { id: "PublicSiteForm_Title", value: "Your own OneFront site" },
    { id: "PublicSiteForm_Description", value: "Create your own OneFront site to let your members update their details." },
    { id: "PublicSiteForm_Description_CreatedSite", value: "A site has been created. Your members can access the site with details below." },
    { id: "PublicSiteForm_Success_Created", value: "Site created" },
    { id: "PublicSite_TitleColumn", value: "Title/Headline" },
    { id: "PublicSite_TitleColumn_Validation", value: "You have to fill in Title" },
    { id: "PublicSite_SiteUrlColumn", value: "Url" },
    { id: "PublicSite_SiteUrlColumn_Validation", value: "Your have to fill in Url" },
    { id: "PublicSite_Create", value: "Create" },
    { id: "PublicSite_Delete", value: "Delete site" },
    { id: "PublicSite_LogoTypeTitle", value: "Logotype" },
    { id: "PublicSite_EditMember", value: "Member can edit contact details" },
    { id: "PublicSite_NewMember", value: "Member can register first time as member" },
    { id: "PublicSite_PresentLogo", value: "Present logo" },
    { id: "PublicSite_SiteDescription", value: "Description to your members" },
    { id: "PublicSite_SiteDescriptionHelpText", value: "This text will be above the forms for new member / edit member. For example some helptext or contact details." },
    { id: "AddNote_ModalTitle", value: "Notes" },
    { id: "AddNote_NoteColumn", value: "Note" },
    { id: "AddNote_NoteColumnPlaceHolder", value: "Add new note" },
    { id: "EditMember_ModalClose", value: "Close" },
    { id: "AddNote_SaveNote", value: "Save note" },
    { id: "AddNote_NoNotes", value: "No notes yet." },
    { id: "AddPayment_ModalTitle", value: "Payments" },
    { id: "AddPayment_SaveNote", value: "Save payment" },
    { id: "AddPayment_NoteColumnPlaceHolder", value: "Amount" },
    { id: "AddPayment_YearColumn", value: "Year" },
    { id: "AddPayment_NoPayments", value: "No earlier registrede payments" },
    { id: "AddPayment_PaymentDateColumn", value: "Payment date" },
    { id: "AddPayment_PaymentDatePlaceHolder", value: "Date" },
    { id: "EditGroup_GroupName", value: "Name" },
    { id: "EditGroup_ModalTitle", value: "Edit group" },
    { id: "EditGroup_NameExist", value: "Groupname already exist" },
    { id: "EditGroup_Error", value: "Error occured, please try again later." },
    { id: "EditAdmin_Admin", value: "Edit administrator" },
    { id: "EditAdmin_MoreThenOneGlobalAdmin", value: "There has to be at least one administrator with global rights." },
    { id: "AddMember_ErrorSaving", value: "Failed adding member. Email already exists." },
    { id: "AddMember_Title", value: "Add member" },
    { id: "AddMember_Title", value: "Lägg till medlem" },
    { id: "AddMember_Ok", value: "Spara" },
    { id: "AddMember_Cancel", value: "Avbryt" },
    { id: "EmailForm_EmailsSent", value: 'Emails has been sent.' },
    { id: "EmailForm_Subject", value: "Subject" },
    { id: "EmailForm_SubjectRequired", value: "Your e-mail requires a Subject" },
    { id: "EmailForm_Message", value: "Message" },
    { id: "EmailForm_MessageRequired", value: 'Message is mandatory' },
    { id: "EmailForm_AlertSuspendedMessage", value: "Email to groups has been temporarily suspended for this account" },
    { id: "EmailForm_AlertSuspendedDescription", value: "More then 30 e-mails to group's has been sent the last 30 days. This warning will be automatically removed. Feel free to contact us if you have any questions." },
    { id: "EmailForm_PremiumSendingMessageToGroups", value: ' to start sending e-mails to groups.' },
    { id: "EmailForm_SendEmailToGroupButtonText", value: 'Send e-mail to group' },
    { id: "EmailForm_ConfirmText", value: 'Confirm e-mail send' },
    { id: "EmailForm_ConfirmText1", value: 'E-mail will be sent to all members in group (' },
    { id: "EmailForm_ConfirmText2", value: ')' },
    { id: "EmailForm_Group", value: 'Send mail to' },
    { id: "EmailForm_AttachFile", value: "Attach file" },
    { id: "EmailForm_SearchUser", value: 'Search member' },
    { id: "EmailForm_SelectGroup", value: 'Choose from category' },
    { id: "EmailForm_EmailsSentFailed", value: 'Error occured during e-mail send.' },
    { id: "EmailForm_From", value: "From:" },
    { id: "EmailForm_ChooseReceiver", value: 'Choose receiptients of e-mail' },
    { id: "EmailForm_ChooseCategories", value: 'Choose groups/categories' },
    { id: "EmailForm_CreateSendList", value: 'Create sendlist' },
    { id: "EmailForm_LoadFromEmailTemplate", value: 'Utgå från e-postmall' },
    { id: "EmailForm_Step1", value: 'Step 1 - Choose recipients' },
    { id: "EmailForm_Step2", value: 'Step 2 - Write content & send' },
    { id: "EmailForm_ExternalMailHeader", value: 'Send email to' },
    { id: "EmailForm_OverrideEmailSetting", value: 'Include members that does not wish to receive info by e-mail' },
    { id: "EmailForm_ChosenReceivers", value: 'choosed recipients' },
    { id: "EmailForm_SelectOneGroup", value: 'Your have to select at least one group.' },
    { id: "UploadPayments_PaymentsFromFileTitle", value: "Upload payments from file" },
    { id: "Users_UsersFromFileTitle", value: "Import / Export members" },
    { id: "UploadFile_SelectFile", value: 'Attach file' },
    { id: "UploadFile_TooBig", value: 'File is too big (max 4MB).' },
    { id: "UploadFile_MaxFiles", value: 'Max 4 attached files.' },
    { id: "MemberFieldSettings_Title", value: "Membersettings" },
    { id: "MemberList_ErrorFreeVersionMaxGroupMembers", value: "Cannot create more then 25 members in Free version. Upgrade to Premium to add more members." },
    { id: "MemberList_ErrorFreeVersionMaxGroupMembersPremium", value: "Cannot create more then 999 members in Premium version." },
    { id: "MemberList_DefaultAllMembersName", value: "All" },
    { id: "MemberList_NameColumn", value: "Name" },
    { id: "MemberList_EmailColumn", value: "Email" },
    { id: "MemberList_UniqueIdColumn", value: "MemberId" },
    { id: "MemberList_LastNameColumn", value: "Lastname" },
    { id: "MemberList_FirstNameColumn", value: "Firstname" },
    { id: "MemberList_AdressColumn", value: "Address" },
    { id: "MemberList_PostalAreaColumn", value: "Postal area" },
    { id: "MemberList_PostalCodeColumn", value: "Postal code" },
    { id: "MemberList_CountryColumn", value: "Country" },
    { id: "MemberList_CreatedColumn", value: "Created" },
    { id: "MemberList_PhoneColumn", value: "Phone" },
    { id: "MemberList_CompanyColumn", value: "Company" },
    { id: "MemberList_DeleteMemberConfirmMessage", value: "Delete member, are you sure?" },
    { id: "MemberList_AddMemberHeader", value: "Add members to group" },
    { id: "MemberList_AddMemberBulkHeader", value: "Add members by uploading csv/txt" },
    { id: "MemberList_SendEmailHeader", value: "Send e-mail to" },
    { id: "MemberList_SearchGroupMember", value: "Search name / email / phone" },
    { id: "MemberList_EmptyMessage", value: "No members added yet." },
    { id: "MemberList_MembersFoundCount1", value: "Total" },
    { id: "MemberList_MembersFoundCount2", value: "members found." },
    { id: "MemberList_ActionColumn", value: "Actions" },
    { id: "MemberList_GroupsColumn", value: "Groups" },
    { id: "MemberList_Download", value: "Download" },
    { id: "MemberList_TitleColumn", value: "Title" },
    { id: "MemberList_Extra1Column", value: "Extra1" },
    { id: "MemberList_Extra2Column", value: "Extra2" },
    { id: "MemberList_Extra3Column", value: "Extra3" },
    { id: "MemberList_LastModifiedColumn", value: "Last updated" },
    { id: "MemberList_StatusColumn", value: "Status" },
    { id: "Users_AdminTitle", value: "Admin" },
    { id: "AdminList_Email", value: "Email" },
    { id: "AdminList_Role", value: "Role" },
    { id: "AdminList_Name", value: "Name" },
    { id: "AdminList_MembersFoundCount1", value: "Total" },
    { id: "CreateCompanyUser_Info", value: "Fill in the details below to finish the registration." },
    { id: "AdminList_MembersFoundCount2", value: "administrators was found." },
    { id: "AdminList_WaitingForUserRegistration", value: "Waiting for account to be activated by user. Mail has been sent." },
    { id: "AddAdmin_AddButton", value: "Add administrator" },
    { id: "Admins_AdminsTitle", value: "Administrators" },
    { id: "Admins_Description", value: "Manage the administrator list. Administrators can login to this account and edit events/members." },
    { id: "AddAdmin_Title", value: "Add administrator" },
    { id: "AddAdmin_Role", value: "Role" },
    { id: "AddAdmin_Email", value: "Email" },
    { id: "AddAdmin_EmailAlreadyExist", value: "There is already a administrator with email " },
    { id: "AddAdmin_OnlyOneAdminInFreeVersion", value: "Only one admin in the free version" },
    { id: "AddMember_AddButton", value: "Add member" },
    { id: "UploadMembers_WrongFormat", value: "File was empty or in wrong format." },
    { id: "UploadMembers_Found", value: "Found" },
    { id: "UploadMembers_CSV", value: "Template Example (CSV)" },
    { id: "UploadMembers_XLSX", value: "Template Example (XLSX)" },
    { id: "UploadMembers_ErrorMoreThenMaxMembersInFree1", value: "in file. Cannot create more then 25 members in Free version. Upgrade to" },
    { id: "UploadMembers_ErrorMoreThenMaxMembersInFree2", value: "to add more members." },
    { id: "UploadMembers_ErrorMoreThenMaxMembersInPremium", value: "in file. Cannot create more then 999 members in Premium version." },
    { id: "UploadMembers_UploadCsvDescription1", value: "Create several members at once, when for example importing members from another system. Its possible either to upload with .csv or .xlx files. See examples and templates below:" },
    { id: "UploadMembers_UploadCsvDescription2", value: "MemberId;FirstName;LastName;Email;Email2;Address;PostalCode;PostalArea;Title;Country;PhoneNumber;Company;Extra1;Extra2;Extra3;Categories" },
    { id: "UploadMembers_UploadFileText", value: "Upload file" },
    { id: "UploadMembers_ModalTitlePreview", value: "Preview file upload" },
    { id: "UploadMembers_ModalAdd", value: "Add " },
    { id: "UploadMembers_ModalMembers", value: " members" },
    { id: "UploadMembers_ModalOk", value: " Ok" },
    { id: "UploadMembers_ModalCancel", value: "Cancel" },
    { id: "UploadMembers_FoundInFile1", value: "Total" },
    { id: "UploadMembers_FoundInFile2", value: "members found in file." },
    { id: "UploadMembers_FoundInFile3", value: "errors. See below in red which members has error. Click button 'Add' to continue upload members to group." },
    { id: "UploadMembers_NoMembersFound", value: "No members found in file" },
    { id: "UploadMembers_SelectGroup", value: "Choose group" },
    { id: "UploadMembers_SelectGroupHelpText", value: "Teh users uploaded from file will be added to the group." },
    { id: "UploadPaymentData_UploadCsvDescription1", value: "Upload payments in the following format" },
    { id: "UploadPaymentData_UploadCsvDescription2", value: "MemberId;Year(YYYY);Amount" },
    { id: "UploadPaymentData_UploadFileText", value: "Upload payments from file" },
    { id: "UploadPaymentData_MemberId", value: "MemberId" },
    { id: "UploadPaymentData_Year", value: "Year" },
    { id: "UploadPayments_Example", value: "Download template (xlsx)" },
    { id: "UploadPaymentData_PaymentDate", value: "Paymentdate" },
    { id: "UploadPaymentData_Amount", value: "Amount" },
    { id: "UploadPaymentData_FoundInFile1", value: "Total" },
    { id: "UploadPaymentData_FoundInFile2", value: "payments found in file." },
    { id: "UploadPaymentData_FoundInFile3", value: "errors.See below in red which payments failed. Click button 'Add' to continue upload payments." },
    { id: "UploadPaymentData_NoPaymentsFound", value: "Inga betalningar hittades i filen" },
    { id: "UploadPaymentData_ModalMembers", value: " payments" },
    { id: "PublicSiteRegisterComplete_Title", value: "Thanks! You details has been saved" },
    { id: "PublicSiteRegisterComplete_Description", value: "A e-mail has been sent to your mail. Open the mail and click on link to finish the registration. It can take a few minutes for e-mail to arrive. Don't forgot to check the junk email folder." },
    { id: "PublicSiteNewmemberActiveAcount_Title", value: "Finished!" },
    { id: "PublicSiteNewmemberActiveAcount_SubTitle", value: "Your registration is not completed." },
    { id: "PublicSiteNewmemberActiveAcount_Failed", value: "Error occured. Please try again later." },
    { id: "PublicSiteNewMember_NewMemberTitle", value: "Registration new member" },
    { id: "PublicSiteNewMember_Error", value: "E-mail is already taken." },
    { id: "UploadPaymentData_AmountValidationMessage", value: "Amount is not valid format: " },
    { id: "UploadPaymentData_MemberIdValidationMessage", value: "No member found with id: " },
    { id: "UploadPaymentData_YearValidationMessage", value: "Year is not valid: " },
    { id: "PublicSiteEditMember_Email", value: "Email" },
    { id: "PublicSiteEditMember_PlaceHolder_Email", value: "Type your registred e-mail" },
    { id: "PublicSiteEditMember_SendEmail", value: "Send login link" },
    { id: "PublicSiteEditMember_Email_Required", value: "You have to fill in a e-mail" },
    { id: "PublicSiteMailHasBeenSent_Title", value: "A mail has been sent." },
    { id: "PublicSiteMailHasBeenSent_Description", value: "A email has been sent with a loginlink. Open your e-mail account and click on the link to continue and update your contact details. It can take a few minutes for the email to arrive, don't forget to check your junkmail." },
    { id: "PublicSiteEditMember_Error", value: "Error occured. Please check that the e-mail adress is correct. Is the error remains, please contact an administrator." },
    { id: "PublicSiteEditMember_CompanyNotFound", value: "This link could not be found" },
    { id: "PublicSiteEditMember_MemberNotFoundOrExpired", value: "This link could not be found. It could have expired." },
    { id: "PublicSiteDetailsHaveBeenSaved_Title", value: "Thanks! Your details has been updated." },
    { id: "PublicSiteDetailsHaveBeenSaved_Description", value: "" },
    { id: "PublicSiteRegisterComplete_Title", value: "Tack! Din registrering är klar" },
    { id: "PublicSiteRegisterComplete_Title", value: "" },
    { id: "PublicSiteRegisterComplete_Description", value: "Tack! Din registrering är klar" },
    { id: "PublicSiteRegisterComplete_Description", value: "Tack! Din registrering är klar" },
    { id: "HistoryEvent_AddMember", value: "Member was added" },
    { id: "HistoryEvent_DeleteMember", value: "Member was removed" },
    { id: "HistoryEvent_UpdateMember", value: "Member was updated" },
    { id: "HistoryEvent_UpdatedMemberByMember", value: "Member updated their contact details" },
    { id: "HistoryEvent_AddMemberByMember", value: "Member registered" },
    { id: "HistoryEvent_MemberActivated", value: "Member activated account" },
    { id: "History_Date", value: "Date" },
    { id: "History_EventType", value: "Type of event" },
    { id: "History_EventText", value: "Description" },
    { id: "History_NoHistoryYet", value: "No events yet" },
    { id: "History_ContactDetails", value: " contact details" },
    { id: "History_ContactDetailsUpdate", value: " updated their contact details" },
    { id: "History_RegistredAsMember", value: " registred as member" },
    { id: "History_ActivatedAccount", value: " activated their account" },
    { id: "History_Added", value: " added " },
    { id: "History_Updated", value: " updated " },
    { id: "History_Removed", value: " removed " },
    { id: "History_Field", value: "Field" },
    { id: "History_OldValue", value: "Old value" },
    { id: "History_NewValue", value: "New value" },
    { id: "Member_ChangedTo", value: " changed to " },
    { id: "History_AsMember", value: " as member" },
    { id: "Users_UserHistoryTitle", value: "Member events" },
    { id: "EmailLogs_Email", value: "E-mail" },
    { id: "EmailLogs_Date", value: "Date" },
    { id: "EmailLogs_Subject", value: "Subject" },
    { id: "EmailLogs_From", value: "From" },
    { id: "EmailLogs_Reason", value: "Status text" },
    { id: "EmailLogs_Status", value: "Status" },
    { id: "EmailLogs_EmptyMessage", value: "No emails found" },
    { id: "EmailLogs_Requests", value: "Sent" },
    { id: "EmailLogs_Delivered", value: "Received" },
    { id: "EmailLogs_Opened", value: "Open" },
    { id: "EmailLogs_Clicks", value: "Clicked" },
    { id: "EmailLogs_Bounce", value: "Bounce" },
    { id: "EmailLogs_HardBounces", value: "Hard bounce" },
    { id: "EmailLogs_SoftBounces", value: "Soft bounce" },
    { id: "EmailLogs_Spam", value: "Junkmail" },
    { id: "EmailLogs_Defered", value: "Defered" },
    { id: "EmailLogs_Blocked", value: "Blocked" },
    { id: "EmailLogs_Error", value: "Error" },
    { id: "EmailLogs_ShowOnlyError", value: "Show only errors" },
    { id: "EmailLogs_Search", value: "Search" },
    { id: "EmailLogs_EmailLogsTitle", value: "E-mail logs" },
    { id: "EmailLogs_StartDate", value: "Startdate" },
    { id: "EmailLogs_EndDate", value: "Enddate" },
    { id: "EmailLogs_Max90Days", value: "You can maximum search for total 90 days at each search." },
    { id: "TopHeader_Home", value: "Home" },
    { id: "TopHeader_Functions", value: "Features" },
    { id: "TopHeader_Functions", value: "Funktioner" },
    { id: "TopHeader_FunctionsOrganisations", value: "For member organisations" },
    { id: "TopHeader_FunctionsCompanies", value: "For companies" },
    { id: "TopHeader_Pricing", value: "Pricing" },
    { id: "TopHeader_Groups", value: "Groups" },
    { id: "TopHeader_MyEvents", value: "Events" },
    { id: "TopHeader_Contact", value: "Contact" },
    { id: "TopHeader_Events", value: "Events" },
    { id: "TopHeader_Users", value: "Members" },
    { id: "TopHeader_CreateEvent", value: "Create new event" },
    { id: "TopHeader_Settings", value: "Settings" },
    { id: "TopHeader_English", value: "English" },
    { id: "TopHeader_Swedish", value: "Swedish" },
    { id: "TopHeader_Logout", value: "Logout" },
    { id: "TopHeader_Login", value: "Login" },
    { id: "TopHeader_Communicate", value: "Communicate" },
    { id: "TopHeader_CreateAccount", value: "Create account" },
    { id: "Users_UsersTitle", value: "Members" },
    { id: "SendMail_SendMailTitle", value: "Send email" },
    { id: "SendMail_SendMailExternalTitle", value: "Send email external" },
    { id: "SendMail_SendMailCategoryTitle", value: "Send mail to group/category" },
    { id: "SendMail_SendMailMemberTitle", value: "Send mail to individual member" },
    { id: "EmailLogs_EmailLogsTitle", value: "Email follow-up" },
    { id: "UploadImage_Error", value: "Error uploading the file" },
    { id: "UploadImage_WrongFormat", value: "You can only upload JPG/PNG file!" },
    { id: "UploadImage_TooBig", value: "Image must smaller than 1MB!" },
    { id: "UploadImage_UploadImageButton", value: "Upload image" },
    { id: "Calender_AllDay", value: "All-day" },
    { id: "ConfirmEmail_ActiveAccountPaid", value: "You're account is now active and you can login. Login and head over to 'Pricing' page to activate paid subscription." },
    { id: "ConfirmEmail_ActiveAccount", value: "You're account is now active and you can start creating events right away after Login." },
    { id: "ConfirmEmail_Success", value: "Successfully confirmed your e-mail!" },
    { id: "ConfirmEmail_Login", value: "Login" },
    { id: "Contact_Header", value: "Contact" },
    { id: "Contact_Title", value: "OneFront 360 - Contact" },
    { id: "Contact_ReachOut", value: "To reach out to us, please send an e-mail to " },
    { id: "Contact_ReachOut2", value: "We will do our best to reply within 24 hours." },
    { id: "Contact_Question1Question", value: "Why did my guest not get the e-mail invitation?" },
    { id: "Contact_Question1Reply", value: "Sometimes the e-mail ends up in the users spam folder/junk e-mail. Ask the users to check there." },
    { id: "Create_DateTimeTitle", value: "Date/times" },
    { id: "Create_Name", value: "Name" },
    { id: "Create_Name_Required", value: "Name of the event is needed." },
    { id: "Create_Name_Placeholder", value: "What is the name of the event" },
    { id: "Create_Description", value: "Description" },
    { id: "Create_Description_Placeholder", value: "Description of the event, instructions, other information" },
    { id: "Create_Location", value: "Location" },
    { id: "Create_Times", value: "Add your date/times" },
    { id: "Create_Duration", value: "Duration" },
    { id: "Create_AllDay", value: "All day" },
    { id: "Create_Timezone", value: "Timezone" },
    { id: "Create_Timezone_Placeholder", value: "Select a timezone" },
    { id: "Create_ImportGoogleCalendar", value: "Import Google Calendar" },
    { id: "Create_HideGoogleCalendar", value: "Hide Google Calender" },
    { id: "Create_Settings", value: "Settings" },
    { id: "Create_Answers", value: "Answers" },
    { id: "Create_Answers_Placeholder", value: "Choose the available options your guests can choose from. Default is Yes/No/Maybe" },
    { id: "Create_Deadline", value: "Deadline" },
    { id: "Create_Deadline_Placeholder", value: "Set a deadline for your event. After the deadline no more bookings is allowed." },
    { id: "Create_Deadline_Set", value: "Set deadline" },
    { id: "Create_Deadline_Premium_SetDeadline", value: "to set deadline." },
    { id: "Create_GuestsMax", value: "Guests (max)" },
    { id: "Create_Guests_Premium_ToAllowMoreGuests", value: "to allow more then 25 guests." },
    { id: "Create_Guests_Premium_RestrictNumberOfGuests", value: "Restrict number of answers. No more bookings is allowed after max count" },
    { id: "Create_YesNoMaybe", value: "Yes / No / Maybe" },
    { id: "Create_YesNo", value: "Yes / No" },
    { id: "Create_Yes", value: "Yes" },
    { id: "Create_Visibility", value: "Visibility" },
    { id: "Create_Visibility_Placeholder", value: "(Only you can see participants details)" },
    { id: "Create_Visibility_HideParticipants", value: "Hide participants list" },
    { id: "Create_AutomaticReminder", value: "Automatic reminder" },
    { id: "Create_AutomaticReminder_Placeholder", value: "(Send automatic reminder to the non-repliers 2 days before deadline or first date)" },
    { id: "Create_AutomaticReminder_Send", value: "Send automatic reminder" },
    { id: "Create_AutomaticReminder_Premium_ToSendAutomaticReminder", value: "to send automatic reminder." },
    { id: "Create_NoAds", value: "Make your poll look more clean and professional by removing ads for your guests." },
    { id: "Create_AutomaticReminder_Premium_ToRemoveAds", value: "to remove ads." },
    { id: "Create_CreateEvent", value: "Create event" },
    { id: "Create_LoginToCreate", value: "You need to Login to create Event. It's free and does not require credit-card." },
    { id: "Create_CreateNewAccount", value: "Create new account" },
    { id: "Toolbar_Next", value: "Next" },
    { id: "Toolbar_Previous", value: "Previous" },
    { id: "Toolbar_Today", value: "Today" },
    { id: "Toolbar_ChooseAnotherWeek", value: "Choose another week" },
    { id: "EventCreated_CreateEventWithID", value: "Event with ID " },
    { id: "EventCreated_CreateEventWithID2", value: " has been created." },
    { id: "EventCreated_CopiedToClipboard", value: "copied to clipboard." },
    { id: "EventCreated_SuccessTitle", value: "Successfully created event with OneFront!" },
    { id: "EventCreated_SuccessDescription", value: "Here is an link you can use to send your invites:" },
    { id: "Event_EventNotFound", value: "This event could not be found." },
    { id: "Event_EventPassed", value: "The deadline has passed on this event. No more answers can be submitted." },
    { id: "Event_EventClosed", value: "This event has been closed." },
    { id: "Event_EventLastDayReply", value: "Last day of reply is:" },
    { id: "Event_Name", value: "Name" },
    { id: "Event_Name_Placeholder", value: "Please input your name" },
    { id: "Event_Email", value: "Email" },
    { id: "Event_Email_Placeholder", value: "Please input your email" },
    { id: "Event_MesssageToOrganizer", value: "Optional message to organizer" },
    { id: "Event_PlaseAnswerAvailabilityValidationMessage", value: "Please answer your availability" },
    { id: "Event_TimezoneInformation", value: "* All times above are in:" },
    { id: "Event_SubmitAnswer", value: "Submit answer" },
    { id: "Event_UpdateAnswer", value: "Update answer" },
    { id: "EventAnswerComplete_Title", value: "Thank you! Your reply has been saved for event " },
    { id: "EventAnswerComplete_Description", value: "An email has been sent to the organizer" },
    { id: "FinalizeRegistration_Title", value: "Almost finished!" },
    { id: "FinalizeRegistration_Description", value: "Almost finished! Click on the button below to finish the registration and get activate your Premium account." },
    { id: "FinalizeRegistration_Activate", value: "Activate account with payment" },
    { id: "ForgotPassword_EmailSent", value: "An e-mail has been sent." },
    { id: "ForgotPassword_Title", value: "Forgot password" },
    { id: "ForgotPassword_Description", value: "Reset your password by enter your e-mail below" },
    { id: "ForgotPassword_Email", value: "Email" },
    { id: "ForgotPassword_Email_Placeholder", value: "Please input your e-mail" },
    { id: "ForgotPassword_EmailButtonText", value: "Send" },
    { id: "Login_Error", value: "Password or e-mail is incorrect. Please check and try again." },
    { id: "Login_UserNotCreatedError", value: "This user is not created here at OneFront yet." },
    { id: "Login_Title", value: "Login" },
    { id: "Login_TitleDescription", value: "Login to schedule your next event" },
    { id: "Login_Email", value: "Email" },
    { id: "Login_Email_Placeholder", value: "input e-mail" },
    { id: "Login_Email_Validation", value: "Please input your e-mail" },
    { id: "Login_Password", value: "Password" },
    { id: "Login_Password_Placeholder", value: "input password" },
    { id: "Login_Password_Validation", value: "Please input your password" },
    { id: "Login_LoginButtonText", value: "Login" },
    { id: "Login_CreateAccount", value: "Create account" },
    { id: "Login_LoginWithGoogle", value: "Login with Google" },
    { id: "Login_ForgotPassword", value: "Forgot password?" },
    { id: "Login_DividerOr", value: "OR" },
    { id: "CreateUser_ErrorAlreadyExist", value: "The account/email already exists." },
    { id: "CreateUser_Title", value: "Create account" },
    { id: "CreateUser_TitleDescription", value: "Scheduling the easy way" },
    { id: "CreateUser_FirstName", value: "First name" },
    { id: "CreateUser_FirstName_Validation", value: "Please input your firstname" },
    { id: "CreateUser_LastName", value: "Last name" },
    { id: "CreateUser_LastName_Validation", value: "Please input your lastname" },
    { id: "CreateUser_Email", value: "Email" },
    { id: "CreateUser_Email_Validation", value: "Please input your email" },
    { id: "CreateUser_Password", value: "Password" },
    { id: "CreateUser_Password_Validation", value: "Please input your password" },
    { id: "CreateUser_Create", value: "Create" },
    { id: "CreateUser_Login", value: "Login" },
    { id: "CreateUser_SignUpWithGoogle", value: "Sign-up with Google" },
    { id: "CreateUser_AlreadySignedUp", value: "Already signed-up?" },
    { id: "CreateUser_Login", value: "Login" },
    { id: "NewUserCreated_Title", value: "Successfully created an account with OneFront!" },
    { id: "NewUserCreated_Description", value: "An e-mail verification link has been sent to your email to verify your account." },
    { id: "NewUserCreatedWithProvider_Title", value: "Successfully created an account with OneFront!" },
    { id: "NewUserCreatedWithProvider_Description", value: "You are good to go. Login and start right away with creating your events." },
    { id: "CreateCompanyUser_InviteNotFound", value: "Invite could not be found. Contact the administrator who send the invite." },
    { id: "Pricing_Title", value: "Pricing" },
    { id: "Pricing_EmailYearly", value: "email/year" },
    { id: "Pricing_Free", value: "Free" },
    { id: "Pricing_Free_Description", value: "For people getting started." },
    { id: "Pricing_CurrentPlan", value: "Current plan" },
    { id: "Pricing_SignUp", value: "Sign up" },
    { id: "Pricing_FreePayment", value: "0$" },
    { id: "Pricing_Premium", value: "Premium" },
    { id: "Pricing_Basic", value: "Basic" },
    { id: "Pricing_Enterprise", value: "Enterprise" },
    { id: "Pricing_PremiumPayment", value: "3000 sek/year" },
    { id: "Pricing_Enterprise", value: "Enterprise" },
    { id: "Pricing_BasicPayment", value: "900 sek/year" },
    { id: "Pricing_EnterprisePayment", value: "6000 sek/year" },
    { id: "Pricing_PremiumDescription", value: "More functions, more data" },
    { id: "Pricing_BasicDescription", value: "For smaller organisations" },
    { id: "Pricing_BasicDescription", value: "To get started" },
    { id: "Pricing_EnterpriseDescription", value: "For larger organisations" },
    { id: "Pricing_UpgradeToPremium", value: "Upgrade to Premium" },
    { id: "Pricing_UpgradeToBasic", value: "Upgrade to Basic" },
    { id: "Pricing_UpgradeToEnterprise", value: "Upgrade to Enterprise" },
    { id: "PricingComplete_Success", value: "Successfully upgraded account!" },
    { id: "PricingComplete_Description", value: "An e-mail with receipts has been send to your e-mail." },
    { id: "Start_Title", value: "Digital membership management with OneFront" },
    { id: "Start_Description", value: "OneFront360 is a easy, safe and cost effecient service for organisations to manage contact details and several other functions. The base version includes administration of:<ul><li>Contact details for companies/organisations</li><li>Membership management</li><li>Manage events (bookings)</li><li>Communication (e-mail, sms)</li></ul>" },
    { id: "Start_Description1", value: "You have full control over your orgainisations details and can at any time import/export your details and payments to Excel/Csv, which makes it easy to get started or finish your subscription and take with you the details. OneFront360 is built in modules with modern technology and can be adapted for specific needs.<br/><br/>Does this sound interesting? Feel free to try free subscrption or send and e-mail to support@onefront360.com and we will contact you and tell more." },
    { id: "Start_Register", value: "Register for free" },
    { id: "Start_EventInShort1", value: "Get control of  your data" },
    { id: "Start_EventInShort2", value: "No credit card needed" },
    { id: "Start_EventInShort3", value: "Get started in a couple of minutes" },
    { id: "Start_EventInShort4", value: "Easy way finding time for everyone" },
    { id: "Start_EventStep1", value: "Membership management" },
    { id: "Start_EventStep2", value: "Events" },
    { id: "Start_EventStep3", value: "Communication" },
    { id: "Start_FeaturesTitle", value: "Features" },
    { id: "Start_EventFeatureTitle4", value: "Own site for your members" },
    { id: "Start_EventFeatureDescription4", value: "Smart forms for you members to integrate with existing site for creating/updating member." },
    { id: "Start_EventFeatureTitle2", value: "Restrict event" },
    { id: "Start_EventFeatureDescription2", value: "Restrict answers with to a max guests or set a deadline" },
    { id: "Start_EventFeatureTitle3", value: "Reminders" },
    { id: "Start_EventFeatureDescription3", value: "Send reminders to the non-responders" },
    { id: "Start_EventFeatureTitle1", value: "Memberlist" },
    { id: "Start_EventFeatureDescription1", value: "Organize your members into categories with name & addresses." },
    { id: "Start_EventFeatureTitle5", value: "Export your data" },
    { id: "Start_EventFeatureDescription5", value: "Export your data to excel" },
    { id: "Start_EventFeatureTitle6", value: "Mail" },
    { id: "Start_EventFeatureDescription6", value: "Both you and youre guests are updated by e-mail." },
    { id: "Start_EventFeatureTitle7", value: "Branding" },
    { id: "Start_EventFeatureDescription7", value: "Brand your poll with your company logo or your own photo." },
    { id: "Start_EventFeatureTitle8", value: "Handle payments" },
    { id: "Start_EventFeatureDescription8", value: "Keep track on which members has paid yearly fee." },
    { id: "Settings_Account", value: "Account" },
    { id: "Settings_UserName", value: "Name" },
    { id: "Settings_Subscription", value: "Subscription" },
    { id: "Settings_ReadMorePremium", value: "Read more about Premium" },
    { id: "Settings_ActiveSubscription", value: "You have an subscription on OneFront created with:" },
    { id: "Settings_GoToFastSpring", value: "Go to FastSpring to manage your subscription:" },
    { id: "Settings_ManageYourSubscription", value: "Manage your subscription" },
    { id: "Settings_ManageYourSubscriptionDescription", value: "For example - change credit card or cancel subscription." },
    { id: "Settings_SubscriptionState", value: "State of subscription:" },
    { id: "Settings_FreeAccountDescription", value: "You account is now Free. Click on the button to read more about benefits of Premium account and sign-up." },
    { id: "Settings_Payment", value: "Payments" },
    { id: "Admins_Payments", value: "Payments" },
    { id: "EventDetails_EnabledNotification", value: "Active" },
    { id: "EventDetails_DisabledNotification", value: "Disabled" },
    { id: "MyEventDetails_BrandTitle", value: "Branding" },
    { id: "MyEventDetails_ClipBoard", value: "Copied to clipboard" },
    { id: "MyEventDetails_Saved", value: "Image has been saved." },
    { id: "MyEventDetails_SorryOnlyPremium", value: "Sorry! This feature is only for Premium. Go to Pricing page to upgrade your account." },
    { id: "MyEventDetails_HasBeenRemoved", value: "Image has been removed from event." },
    { id: "MyEventDetails_ShareYourEvent", value: "Share your event" },
    { id: "MyEventDetails_ShareYourEventDescription", value: "Share your invite, copy the link below and send it to your participants:" },
    { id: "MyEventDetails_SendInvitesByEmail", value: "Send invites by e-mail" },
    { id: "MyEventDetails_Replies", value: "Replies" },
    { id: "MyEventDetails_Download", value: "Download" },
    { id: "MyEventDetails_NoRepliesFromGuests", value: "No replies from the guests yet. Have you sent the link to your guests?" },
    { id: "MyEventDetails_SendInvites", value: "Sent invites" },
    { id: "MyEventDetails_Reminders", value: "Reminders" },
    { id: "MyEventDetails_BrandEvent", value: "Brand your event" },
    { id: "MyEventDetails_BannerImage", value: "Banner image" },
    { id: "MyEventDetails_BrandingDescription", value: "With branding image you make your can select a picture as header for your event." },
    { id: "MyEventDetails_BrandingCurrentImage", value: "Your current banner image" },
    { id: "MyEventDetails_RemoveBranding", value: "Remove the image from event." },
    { id: "MyEventDetails_SelectNewImage", value: "Select new image" },
    { id: "MyEventDetails_UploadImageOnlyPremium", value: "Upload image (only Premium account)" },
    { id: "MyEventDetails_SelectImage", value: "Select image" },
    { id: "MyEventDetails_SaveNewImage", value: "Save new image" },
    { id: "MyEventDetails_ColorsForYourEvent", value: "Colors for your event" },
    { id: "MyEventDetails_SelectColors", value: "Select the colors of buttons and links." },
    { id: "MyEventsEdit_UpdateEvent", value: "Update event" },
    { id: "MyEventsEdit_Name_Label", value: "Name" },
    { id: "MyEventsEdit_Name_Required", value: "Name of the event is needed." },
    { id: "MyEventsEdit_Name_Placeholder", value: "What is the name of the event" },
    { id: "MyEventsEdit_Description_Label", value: "Description" },
    { id: "MyEventsEdit_Description_Placeholder", value: "Description of the event, instructions, other information" },
    { id: "MyEventsEdit_Location_Label", value: "Location" },
    { id: "MyEventsEdit_Location_Placeholder", value: "Description of the event, instructions, other information" },
    { id: "MyEventsEdit_Deadline_Label", value: "Deadline" },
    { id: "MyEventsEdit_Deadline_LastResponse", value: "Last response" },
    { id: "MyEventsEdit_Deadline_Description", value: "After this date no responses can be added." },
    { id: "MyEventsEdit_PremiumDescription", value: "to set deadline." },
    { id: "MyEventsEdit_DeadlineDescriptionText", value: "Set a deadline for your event. After the deadline no more bookings is allowed." },
    { id: "MyEventsEdit_Participants_Label", value: "Participants (max)" },
    { id: "MyEventsEdit_Participants_Description", value: "Max number of participants" },
    { id: "MyEventsEdit_RestrictAnswers", value: "Restrict number of answers. No more bookings is allowed after max count." },
    { id: "MyEventsEdit_UpgradeToPremiumToAllowMoreGuests", value: "to allow more then 25 guests." },
    { id: "MyEventsEdit_RestrictNumberOfAnswers", value: "Restrict number of answers. No more bookings is allowed after max count." },
    { id: "MyEventsEdit_HideParticipantList", value: "Hide participant list" },
    { id: "MyEventsEdit_HideParticipantList_Description", value: "(Only you can see participants details)" },
    { id: "MyEventsEdit_HasGuestsLockedChanges", value: "This event already has guests, the dates can therefore not be changed." },
    { id: "MyEventsEdit_AddTimes", value: "Add times" },
    { id: "MyEventsEdit_Timezone_Label", value: "Timezone" },
    { id: "MyEventsEdit_LengthOfEvent", value: "Length of event" },
    { id: "MyEventsEdit_SelectTimezone", value: "Select a timezone" },
    { id: "MyEventsEdit_Duration", value: "Duration (min)" },
    { id: "MyEventsEdit_CalenderFormat", value: "Calendar format" },
    { id: "MyEventsEdit_LocationOfEvent", value: "Location of the event" },
    { id: "MyEventsEdit_UpdateEventButton", value: "Update event" },
    { id: "MyEventsEdit_Events", value: "Events" },
    { id: "MyEventsEdit_CustomTime", value: "Custom" },
    { id: "MyEventsEdit_AllDay", value: "All-day" },
    { id: "MyEvents_CreatedText", value: "Event created! Click on 'View details' to change your event and invite participants." },
    { id: "MyEvents_Action", value: "Actions" },
    { id: "MyEvents_EventName", value: "Event namn" },
    { id: "MyEvents_SuggestedTimes", value: "Suggested times" },
    { id: "MyEvents_Replies", value: "Replies" },
    { id: "MyEvents_Created", value: "Created" },
    { id: "MyEvents_ViewDetails", value: "View details" },
    { id: "MyEvents_Delete", value: "Delete" },
    { id: "MyEvents_DeleteEventConfirm", value: "Delete event, are you sure?" },
    { id: "MyEvents_MyEvents", value: "My Events" },
    { id: "MyEvents_NoEvents", value: "No events yet." },
    { id: "EmailTemplates_TemplateTitle", value: "Email templates" },
    { id: "EmailTemplates_TemplateTitle", value: "Email templates" },
    { id: "EmailTemplates_Description", value: "Use emailtemplates to re-use common mail subjects and texts." },
    { id: "EmailTemplates_UpgradeTo", value: "Upgrade to" },
    { id: "EmailTemplates_UpgradeTo2", value: "to create more then 1 email template." },
    { id: "EmailTemplates_NoGroupsCreated", value: "No templates has been created yet." },
    { id: "EmailTemplates_Delete", value: "Remove" },
    { id: "EmailTemplates_Members", value: "Members" },
    { id: "EmailTemplates_NoGroupsSelected", value: "No emailtemplates has been selected" },
    { id: "EmailTemplates_DeleteConfirm", value: "Are your sure you want to delete email template?" },
    { id: "EmailTemplates_DeleteConfirmMessage", value: "It will be deleted permanently." },
    { id: "EmailTemplates_NameColumn", value: "Name" },
    { id: "Group_PurposeColumn", value: "Is sent when new member is created" },
    { id: "CreateTemplate_CreateText", value: "Create new email template" },
    { id: "CreateTemplate_CancelText", value: "Cancel" },
    { id: "CreateTemplate_RequiredText", value: "Please fill in name of email template" },
    { id: "CreateTemplate_PlaceHolderText", value: "Name of email template" },
    { id: "CreateTemplate_Subject", value: "Email subject" },
    { id: "CreateTemplate_Body", value: "Email text" },
    { id: "CreateTemplate_ModalHeader", value: "Create new email template" },
    { id: "CreateTemplate_ErrorAlreadyExist", value: "Email template does already exist. Change name and try again." },
    { id: "CreateTemplate_NewMemberRegister", value: "Send this mail automatically to new members that register." },
    { id: "EditTemplate_CreateText", value: "Save" },
    { id: "EditTemplate_CancelText", value: "Cancel" },
    { id: "EditTemplate_ModalHeader", value: "Edit email template" },
    { id: "EditTemplate_ErrorAlreadyExist", value: "EmailTemplate already exist. Change name and try again." },
    { id: "Groups_GroupsTitle", value: "Groups" },
    { id: "Groups_Description", value: "Use groups/categories to separate your members." },
    { id: "Groups_UpgradeTo", value: "Upgrade to" },
    { id: "Groups_UpgradeTo2", value: "to create more then 1 group." },
    { id: "Groups_NoGroupsCreated", value: "No groups created yet" },
    { id: "Groups_Delete", value: "Delete" },
    { id: "Groups_Members", value: "Members" },
    { id: "Groups_NoGroupsSelected", value: "No groups selected" },
    { id: "Groups_DeleteConfirm", value: "Are you sure to delete this group?" },
    { id: "Groups_DeleteConfirmMessage", value: "It cannot be reversed later." },
    { id: "Group_NameColumn", value: "Name" },
    { id: "Group_NumberOfMembers", value: "Members" },
    { id: "AccountForm_Swedish", value: "Swedish" },
    { id: "AccountForm_English", value: "English" },
    { id: "AccountForm_LanguageDescription", value: "Company default language, change this value to change language to your automatic mails, for example reminder-mails." },
    { id: "AccountForm_LanguageSave", value: "Update inställningar" },
    { id: "AccountForm_CompanyEmail", value: "Organisation e-mail" },
    { id: "AccountForm_CompanyName", value: "Organisation name" },
    { id: "AccountForm_CompanyEmailDescription", value: "Used av sender of e-mail to members" },
    { id: "AccountForm_CompanyNameDescription", value: "Used as sender of e-mail to members" },
    { id: "Settings_SettingsTitle", value: "Organisation settings" },
    { id: "Settings_SettingsDomainTitle", value: "Domain/E-mail settings" },
    { id: "HtmlEditorParameter_UniqueId", value: "memberid" },
    { id: "HtmlEditorParameter_FirstName", value: "firstname" },
    { id: "HtmlEditorParameter_LastName", value: "lastname" },
    { id: "HtmlEditorParameter_Email", value: "email" },
    { id: "HtmlEditorParameter_PhoneNumber", value: "phone" },
    { id: "HtmlEditorParameter_Address", value: "address" },
    { id: "HtmlEditorParameter_PostalCode", value: "postalcode" },
    { id: "HtmlEditorParameter_PostalArea", value: "postalarea" },
    { id: "HtmlEditorParameter_UrlPopupText", value: "Give www address to the link below" },
    { id: "HtmlEditorParameter_UrlImagePopupText", value: "Give www address to the image below" },
    { id: "EmailDomainForm_Error", value: "Invalid domain name. No private domain namn can be used, for example gmail, outlook. " },
    { id: "EmailDomainForm_Description", value: "Do you send mail from your own domain?? To verify your e-mails, use the form below." },
    { id: "EmailDomainForm_DomainIsAuthenticated", value: "Your DNS settings is correct. You mail is now sent by your own domain." },
    { id: "EmailDomainForm_AddDomainButton", value: "Add domain" },
    { id: "EmailDomainForm_RemoveDomain", value: "Delete domain" },
    { id: "EmailDomainForm_AuthDomain", value: "Authenticate domain" },
    { id: "EmailDomainForm_DomainCreatedDescription", value: "Publish settings in your domain handler, for eample GoDaddy. After this is done, click on Authenticate domain" },
    { id: "FeaturesMemberOrganisations_Header", value: "Features for member organizations" },
    { id: "FeaturesMemberOrganisations_Header", value: "Features for member organizations" },
    { id: "FeaturesMemberOrganisations_Description", value: "Do you need a system to handle member in a digital and safe way for your organization? Se below which features we at OneFront can help with" },
    { id: "FeaturesMemberOrganisations_Feature1Title", value: "Member database" },
    { id: "FeaturesMemberOrganisations_Feature1Text", value: "A central location for storing all information about your members. Contact details and other information. Konfigure what kind of fields is required for your organisation." },
    { id: "FeaturesMemberOrganisations_Feature2Title", value: "Communication" },
    { id: "FeaturesMemberOrganisations_Feature2Text", value: "Communicate with your members with help of emails sendouts." },
    { id: "FeaturesMemberOrganisations_Feature3Title", value: "Integration" },
    { id: "FeaturesMemberOrganisations_Feature3Text", value: "Let OneFront360 extend your website with smart registration forms.<br/><br/>Let members themself update/access their contact details. Configure which fields is changable." },
    { id: "FeaturesMemberOrganisations_Feature4Title", value: "History" },
    { id: "FeaturesMemberOrganisations_Feature4Text", value: "Tracability and history over changes, e-mail sendouts." },
    { id: "FeaturesMemberOrganisations_Feature5Title", value: "Administrators" },
    { id: "FeaturesMemberOrganisations_Feature5Text", value: "Invite several administrators." },
    { id: "FeaturesMemberOrganisations_Feature6Title", value: "Handle member payments" },
    { id: "FeaturesMemberOrganisations_Feature6Text", value: "Mark which members has paid yearly fee. Send emails to the members that have not paid yet." },
    { id: "FeaturesMemberOrganisations_Feature7Title", value: "Events" },
    { id: "FeaturesMemberOrganisations_Feature7Text", value: "Create events for members or the board. Suggest date and send invitation and see which date is best." },
    { id: "FeaturesMemberOrganisations_Feature8Title", value: "Groups" },
    { id: "FeaturesMemberOrganisations_Feature8Text", value: "Categorize your members into several groups" },
    { id: "FeaturesMemberOrganisations_Feature9Title", value: "Export data" },
    { id: "FeaturesMemberOrganisations_Feature9Text", value: "Import and export data to/from excel." },
    { id: "PageTitle_Start", value: "Membership management for organisations and companies | OneFront 360 EN" },
    { id: "PageTitle_Create", value: "Create account | OneFront 360 EN" },
    { id: "PageTitle_Pricing", value: "Pricing and account levels | OneFront 360 EN" },
    { id: "PageTitle_MemberOrganisations", value: "Functions for member organisations | OneFront 360 EN" },
    { id: "PageTitle_Contact", value: "Contact us | OneFront 360 EN" },
]




