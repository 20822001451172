import { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Row, Space, Table, message } from 'antd';
import 'moment-timezone'
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import { AuthProvider } from '../../Providers/authProvider';
import LeftMenuSettings from './LeftSettings';
import { MemberService } from '../../Services/MemberService';
import { ColumnsType } from 'antd/lib/table';
import { MemberFieldTypes } from '../../Types/MemberFieldTypes';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { WebApiModelsMemberFields } from '../../Repository/eventbookyapi/models/WebApiModelsMemberFields';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const MemberSettings = () => {
    const [fields, setFields] = useState([] as WebApiModelsMemberFields[]);
    const [language, setLanguage] = useState("" as string);

    const [form] = Form.useForm();
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;

    const SaveFields = async () => {
        await MemberService.SaveMemberFields({
            fields: fields
        });

        message.success(GetText("EventBranding_SuccessSaved"));
    };

    const IsVisibleNotPossibleToChange = (field: WebApiModelsMemberFields) => {
        return field.columnType == MemberFieldTypes.FirstName ||
            field.columnType == MemberFieldTypes.LastName ||
            field.columnType == MemberFieldTypes.Address ||
            field.columnType == MemberFieldTypes.PostalArea ||
            field.columnType == MemberFieldTypes.PostalCode ||
            field.columnType == MemberFieldTypes.PhoneNumber ||
            field.columnType == MemberFieldTypes.Email
    }

    const IsMandatoryNotPossibleToChange = (field: WebApiModelsMemberFields) => {
        return field.columnType == MemberFieldTypes.UniqueId
    }

    const HelpTextIdDisabled = (field: WebApiModelsMemberFields) => {
        return field.columnType == MemberFieldTypes.UniqueId || field.columnType == MemberFieldTypes.PhoneNumber
    }

    const OnlyExtraFields = (field: WebApiModelsMemberFields) => {
        return field.columnType == MemberFieldTypes.Extra1 || field.columnType == MemberFieldTypes.Extra2 || field.columnType == MemberFieldTypes.Extra3
    }

    const getFieldText = (field: number) => {
        switch (field) {
            case MemberFieldTypes.UniqueId:
                return GetText("EditMember_UniqueId");
            case MemberFieldTypes.FirstName:
                return GetText("EditMember_LastName");
            case MemberFieldTypes.LastName:
                return GetText("EditMember_LastName");
            case MemberFieldTypes.Email:
                return GetText("EditMember_Email");
            case MemberFieldTypes.Email2:
                return GetText("EditMember_Email2");
            case MemberFieldTypes.Email2:
                return GetText("EditMember_Email2");
            case MemberFieldTypes.Address:
                return GetText("EditMember_Address");
            case MemberFieldTypes.PostalArea:
                return GetText("EditMember_PostalArea");
            case MemberFieldTypes.PostalCode:
                return GetText("EditMember_PostalCode");
            case MemberFieldTypes.Country:
                return GetText("EditMember_Country");
            case MemberFieldTypes.Company:
                return GetText("EditMember_Company");
            case MemberFieldTypes.Title:
                return GetText("EditMember_Title");
            case MemberFieldTypes.PhoneNumber:
                return GetText("EditMember_PhoneNumber");
            case MemberFieldTypes.Extra1:
                return "Extra1";
            case MemberFieldTypes.Extra2:
                return "Extra2";
            case MemberFieldTypes.Extra3:
                return "Extra3";
            case MemberFieldTypes.Payment:
                return GetText("EditMember_Payment");
            case MemberFieldTypes.EmailNotificationPreference:
                return GetText("EditMember_EmailNotificationPreference");
            default:
                return ""
        }
    }

    const updateField = (field: WebApiModelsMemberFields) => {

        var newFields = fields.map((element: WebApiModelsMemberFields, index) => {
            if (field.columnType == element.columnType) {
                return field;
            }
            return element;
        });

        setFields(newFields);
    }

    const updateVisibleInAdmin = (field: WebApiModelsMemberFields, newValue: any) => {
        field.visibleInAdmin = newValue.target.checked;
        updateField(field);
    }

    const updateVisibleInPublic = (field: WebApiModelsMemberFields, newValue: any) => {
        field.visiblePublic = newValue.target.checked;
        updateField(field);
    }

    const updateMandatoryAdmin = (field: WebApiModelsMemberFields, newValue: any) => {
        field.mandatoryAdmin = newValue.target.checked;
        updateField(field);
    }

    const updatemandatoryPublic = (field: WebApiModelsMemberFields, newValue: any) => {
        field.mandatoryPublic = newValue.target.checked;
        updateField(field);
    }

    const updateHelpTextPublic = (field: WebApiModelsMemberFields, newValue: any) => {
        field.helpTextPublic = newValue.target.value;
        updateField(field);
    }

    const updateVisibleFieldName = (field: WebApiModelsMemberFields, newValue: any) => {
        field.nameAdmin = newValue.target.value;
        field.namePublic = newValue.target.value;
        updateField(field);
    }


    const columns: ColumnsType<WebApiModelsMemberFields> = [
        {
            title: GetText("MemberFields_FieldName"),
            dataIndex: 'name',
            key: 'name',
            width: "50px",
            render: (_, field) => (
                getFieldText(field.columnType!)
            ),
        },
        {
            title: GetText("MemberFields_FieldNameVisible"),
            dataIndex: 'name',
            key: 'name',
            width: "50px",
            render: (_, field) => (
                <Input maxLength={100} disabled={!OnlyExtraFields(field)} onChange={(evt: any) => updateVisibleFieldName(field, evt)} value={field.nameAdmin ? field.nameAdmin : ""} />
            ),
        },
        {
            title: GetText("MemberFields_VisibleInAdmin"),
            dataIndex: 'name',
            key: 'name',
            width: "25px",
            render: (_, field) => (
                <Checkbox disabled={IsVisibleNotPossibleToChange(field)} onChange={evt => updateVisibleInAdmin(field, evt)} checked={field.visibleInAdmin} />
            ),
        },

        {
            title: GetText("MemberFields_VisibleInPublic"),
            dataIndex: 'name',
            key: 'name',
            width: "50px",
            render: (_, field) => (
                <Checkbox disabled={IsVisibleNotPossibleToChange(field)} onChange={evt => updateVisibleInPublic(field, evt)} checked={field.visiblePublic} />
            ),
        },
        {
            title: GetText("MemberFields_MandatoryInAdmin"),
            dataIndex: 'name',
            key: 'name',
            width: "50px",
            render: (_, field) => (
                <Checkbox disabled={IsMandatoryNotPossibleToChange(field)} onChange={evt => updateMandatoryAdmin(field, evt)} checked={field.mandatoryAdmin} />
            ),
        },
        {
            title: GetText("MemberFields_MandatoryInPublic"),
            dataIndex: 'name',
            key: 'name',
            width: "50px",
            render: (_, field) => (
                <Checkbox disabled={IsMandatoryNotPossibleToChange(field)} onChange={evt => updatemandatoryPublic(field, evt)} checked={field.mandatoryPublic} />
            ),
        },
        {
            title: GetText("MemberFields_HelpTextPublic"),
            dataIndex: 'helpTextPublic',
            key: 'helpTextPublic',
            width: "200px",
            render: (_, field) => (
                <Input disabled={HelpTextIdDisabled(field)} onChange={(evt: any) => updateHelpTextPublic(field, evt)} value={field.helpTextPublic ? field.helpTextPublic : ""} />
            ),
        },
    ];


    const handleChange = (value: string) => {
        setLanguage(value);
    };

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
            handleChange(user?.companyLanguage!);
        })

        MemberService.GetMemberFields().then(result => {
            setFields(result);
        });
    }, []);



    return <LayoutLeftMenu title={GetText("MemberFieldSettings_Title")} menu={<LeftMenuSettings selected={"memberSettings"} />}>
        <Form form={form}>
            <Table pagination={false} columns={columns} rowKey="Id" dataSource={fields} />
        </Form>
        <Space style={{ marginTop: "20px" }}>
            <BookyButton type="primary" onClick={SaveFields} text={GetText("AddMember_Ok")}></BookyButton>
        </Space>
    </LayoutLeftMenu>
};

export default MemberSettings;