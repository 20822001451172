import '../../Pages/Pricing/Pricing.css'
import { useState } from "react";
import { OneFrontModal } from "../Modals/OneFrontModal";
import { BookyButton } from "../Buttons/BookyButton";
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';

const PageHelp = (props: any) => {
    const [open, setOpen] = useState(false as boolean);
    const GetText = TextService.GetText;

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    return <div style={{ right: 0, position: "absolute", marginRight: "20px" }}>

        <OneFrontModal
            open={open}
            title={GetText("Common_Help")}
            okText={"Ok"}
            onOk={hideModal}
            onCancel={hideModal}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            {props.children}
        </OneFrontModal>

        {props.children != null ? <BookyButton
            type="secondary"
            shape="circle"
            fullWidth={false}
            icon={<InfoCircleTwoTone twoToneColor="#eb2f96" />}
            onClick={showModal}
        >
        </BookyButton> : ""}
    </div>
};

export default PageHelp;



