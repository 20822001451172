import { WebApiModelsMemberField } from "../Repository/eventbookyapi";
import { TextService } from "../Services/TextService";
import { MemberFieldTypes } from "../Types/MemberFieldTypes"

export const mapDynamicInputLabel = (type: MemberFieldTypes, fieldSettings: WebApiModelsMemberField[] | null) => {
    const GetText = TextService.GetText;

    const hasNameAdmin = fieldSettings?.find(p => p.columnType == type)?.nameAdmin

    switch (type) {
        case MemberFieldTypes.UniqueId:
            return GetText("EditMember_UniqueId");
        case MemberFieldTypes.FirstName:
            return GetText("EditMember_FirstName");
        case MemberFieldTypes.LastName:
            return GetText("EditMember_LastName");
        case MemberFieldTypes.Email:
            return GetText("EditMember_Email");
        case MemberFieldTypes.Email2:
            return GetText("EditMember_Email2");
        case MemberFieldTypes.PhoneNumber:
            return GetText("EditMember_PhoneNumber");
        case MemberFieldTypes.Address:
            return GetText("EditMember_Address");
        case MemberFieldTypes.PostalCode:
            return GetText("EditMember_PostalCode");
        case MemberFieldTypes.PostalArea:
            return GetText("EditMember_PostalArea");
        case MemberFieldTypes.Title:
            return GetText("EditMember_Title");
        case MemberFieldTypes.Country:
            return GetText("EditMember_Country");
        case MemberFieldTypes.Company:
            return GetText("EditMember_Company");
        case MemberFieldTypes.Extra1:
            return hasNameAdmin ? hasNameAdmin : GetText("EditMember_Extra1");
        case MemberFieldTypes.Extra2:
            return hasNameAdmin ? hasNameAdmin : GetText("EditMember_Extra2");
        case MemberFieldTypes.Extra3:
            return hasNameAdmin ? hasNameAdmin : GetText("EditMember_Extra3");
        case MemberFieldTypes.Status:
            return GetText("EditMember_Status");
        case MemberFieldTypes.Group:
            return GetText("EditMember_Groups");
        case MemberFieldTypes.EmailNotificationPreference:
            return GetText("EditMember_EmailNotificationPreference");
        default:
            return "Unknown";
    }
}


